import { createStyles } from 'utils/css'

export const body = createStyles(({ colors }) => ({
  backgroundColor: colors.surface100,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center'
}))

export const messageWrapper = createStyles(({ mediaQuery }) => ({
  flex: 1,
  padding: '40px',
  [mediaQuery('sm')]: {
    padding: '88px',
    paddingTop: '0'
  }
}))

export const title = createStyles(({ colors, typography }) => ({
  color: colors.surface500,
  fontSize: typography.heading.sizes.lg,
  fontWeight: 600
}))

export const message = createStyles(({ colors, typography }) => ({
  color: colors.surface400,
  fontSize: typography.body.sizes.xl,
  fontWeight: 400
}))

export const button = createStyles(() => ({
  borderRadius: '16px',
  marginTop: '40px'
}))

export const logo = createStyles(() => ({
  marginBottom: '80px'
}))

export const imageContainer = createStyles(({ colors }) => ({
  flex: 1,
  height: '100%',
  width: '100%',
  minWidth: '300px',
  backgroundColor: colors.surface100
}))

export const imageWrapper = createStyles(() => ({
  position: 'relative',
  width: '100%',
  height: '100%'
}))
