export const getEnvironment = () => {
  const domain = process.env.NEXT_PUBLIC_APP_DOMAIN

  if (domain.indexOf('localhost') !== -1) return 'development'
  if (domain.indexOf('.staging') !== -1) return 'staging'
  if (domain.indexOf('.demo') !== -1) return 'demo'
  return 'production'
}

export const getSubdomainByEnv = () => {
  const environment = getEnvironment()

  switch (environment) {
    case 'development':
    case 'staging':
      return 'staging.'
    case 'demo':
      return 'demo.'
    case 'production':
    default:
      return ''
  }
}

export const isStaging = () =>
  process.env.NEXT_PUBLIC_APP_DOMAIN === 'http://localhost:2000' ||
  process.env.NEXT_PUBLIC_APP_DOMAIN === 'http://localhost:2001' ||
  process.env.NEXT_PUBLIC_APP_DOMAIN === 'https://app.staging.alternative.co' ||
  process.env.NEXT_PUBLIC_APP_DOMAIN === 'https://app.demo.alternative.co' ||
  process.env.NEXT_PUBLIC_APP_DOMAIN ===
    'https://app.staging.alternativepayments.io' ||
  process.env.NEXT_PUBLIC_APP_DOMAIN ===
    'https://app.demo.alternativepayments.io'

export const setEnv = () => {
  switch (process.env.NEXT_PUBLIC_APP_DOMAIN) {
    case 'https://app.alternative.co':
    case 'https://app.alternativepayments.io':
      return 'production'
    case 'https://app.staging.alternative.co':
    case 'https://app.demo.alternative.co':
    case 'https://app.staging.alternativepayments.io':
    case 'https://app.demo.alternativepayments.io':
      return 'staging'
    default:
      return 'development'
  }
}

export const getEnvironmentByDomain = (domain: string) => {
  if (domain.indexOf('localhost') !== -1) return 'development'
  if (domain.indexOf('.staging') !== -1) return 'staging'
  if (domain.indexOf('.demo') !== -1) return 'demo'
  return 'production'
}

export const getPartnerAppDomain = (host: string) => {
  const environment = getEnvironmentByDomain(host)

  switch (environment) {
    case 'development':
      return 'http://localhost:2000'
    case 'staging':
      return 'https://app.staging.alternativepayments.io'
    case 'demo':
      return 'https://app.demo.alternativepayments.io'
    case 'production':
      return 'https://app.alternativepayments.io'
    default:
      return 'https://app.alternativepayments.io'
  }
}

export const getAdminAppDomain = (host: string) => {
  const environment = getEnvironmentByDomain(host)

  switch (environment) {
    case 'development':
      return 'http://localhost:2002'
    case 'staging':
      return 'https://admin.staging.alternativepayments.io'
    case 'demo':
      return 'https://admin.demo.alternativepayments.io'
    case 'production':
      return 'https://admin.alternativepayments.io'
    default:
      return 'https://admin.alternativepayments.io'
  }
}

export const paymentsPortalLocalUrl = 'http://localhost:2001'
